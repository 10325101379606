
@import url("https://rsms.me/inter/inter.css");
@import url('https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@500&family=Bonheur+Royale&family=Dancing+Script:wght@500&family=Engagement&family=Fleur+De+Leah&family=Lobster&family=Love+Light&family=Lovers+Quarrel&family=Marck+Script&family=Mea+Culpa&family=Molle:ital@1&family=Mr+De+Haviland&family=Ole&family=Ruthie&family=Tangerine:wght@700&family=Twinkle+Star&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Open+Sans:wght@300;400;500&family=Raleway&display=swap');


* {
    box-sizing: border-box;
    margin:0;
  }


  :root {
    --primary-color: #0a0a0a;
    --secondary-color: #ffffff;
    --cursorToUse: url('data:image/x-icon;base64,AAACAAEAICACAAAAAAAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAgAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwAAAB+AAAAfgAAAH4AAAB+AAAAPAAAAAAAAAAAAAAAAAAAAAAAAA/////////////////////////////////////////////////////////////////////////////////////////////////////////////////D////gf///wD///8A////AP///wD///+B////w///////////////////8='), auto;
    --fontFamily: 'Bagel Fat One', cursive;
    --secondFontFamily:'Open Sans', sans-serif; 
    min-height: calc(100vh);
    scroll-behavior: smooth !important;
  }
  html {
    font-family: var(--fontFamily);
    font-size: 24px;
    line-height: 1;
    cursor: var(--cursorToUse);
    scroll-behavior: smooth !important;
    background-color: black;
  }

  .body {
    /* background-color: var(--primary-color); */
    color: var(--secondary-color);
    min-height: 100vh;
    /* background-size: contain;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat; */
    z-index: 2 !important;
    pointer-events: none;
  }
  .header {
    position: absolute;
    top: 2.5%; 
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    height: 4rem;
    z-index: 100;
    font-family: 'Bagel Fat One', cursive; 
    position: fixed;
    }
  .header h1 {
    font-size: min(1rem, 1.5vw);
    font-weight: 400;
    /* z-index: 50; */
    pointer-events: all;
  }
  .header h5 {
    font-size: 0.55rem;
    font-weight: 100;
    position: absolute;
    top: 62.5%;
    left: 7.2%;
    display: none;
  }

  .headerMobile {
    position: absolute;
    bottom: -2%; 
    left: -4%;
    width: 110%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 4rem;
    z-index: 100;
    font-size: 0.75rem;
    font-family: 'Bagel Fat One', cursive; 
    position: fixed;
    /* background-color: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) !important; */
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    /* opacity: 100% !important; */
    
  }
  .headerMobile h1 {
    font-size: 0.85rem;
    font-weight: 400;
    text-shadow: 5px 5px 5px #000;
  }
  .headerMobile h6 {
    font-size: 0.4rem;
    position: absolute;
    bottom: 25%;
    left: 42%;
  }
  .headerMobile h5 {
    font-size: 0.45rem;
    font-weight: 100;
    position: absolute;
    top: 62.5%;
    left: 7.2%;
    display: none;
  }


  .bg {
    background-color: #49a1eb;
    /* overflow:auto; */
    min-height: calc(100vh);
  }
  .bgClip {
    background-color: grey;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  img { 
    max-width: 100%;
    text-decoration: none;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
  a.button {
    display: inline-block;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    padding: 0.25rem 0.75rem 0.15rem 0.75rem;
    border-radius: 0.25rem;
  }
  
  section.About {
    min-height: calc(100vh);
  }
  section.About img {
    width: 100%;
  }
  .menuOptions {
    /* position: fixed; */
    top: 0;
    left: 0;
    font-style: normal;
    font-weight: 800;
    margin: 0;
    font-size: 20vh;
    letter-spacing: -0.5rem;
    color: inherit;
  }
  
  .textContent {
    padding: 5rem 10rem 10rem 4rem; /* Top Right Bottom Left */
    font-size: 2rem;
    font-family: var(--secondFontFamily); 
    min-height: 100vh;
    line-height: 1.2;
    font-weight: 200;
    margin-right: 7rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    background-color: #1B5E20;
    --mask: radial-gradient(
        4.66rem at calc(100% - 6.40rem) 50%, 
        #000 99%, 
        #0000 101%
      ) 0 calc(50% - 8rem) / 100% 16rem,
      radial-gradient(
        4.66rem at calc(100% + 2.40rem) 50%, 
        #0000 99%, 
        #000 101%
      ) calc(100% - 4rem) 50% / 100% 16rem repeat-y;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      scroll-behavior: smooth !important;
      will-change: transform, opacity, background-color, z-index;
      /* transition: clip-path 4s;
      clip-path: circle(75%); */
      animation: none;
  }
  .textContentText {
    text-align: center;
    position: fixed;
    left: 20%;
    top: 20%;
    transform: translate(-50%, -50%);
    font-size: 22.5vh;
    font-family: var(--secondFontFamily);
    z-index: 2;
    /* text-decoration: underline; */
    /* text-decoration-color: rgb(255, 255, 255);
    text-decoration-thickness: 1vh; */
    /* text-emphasis: filled white; */
    animation: blackGlowText 4s infinite;
  }
  /* this is cell phone mobile */
  @media only screen and (max-width: 768px) { 
    .textContent {
      will-change: transform, opacity, background-color, z-index;
      margin-right: 0rem;
      margin-top: 5rem;
      padding: 4rem 0.75rem 5rem 0.75rem; /* Top Right Bottom Left */
      -webkit-mask: none;
      /* mask: none; */
      --mask:
      radial-gradient(2.18rem at 50% 3.08rem,#000 99%,#0000 101%) calc(50% - 3rem) 0/6rem 100%,
      radial-gradient(2.18rem at 50% -1.58rem,#0000 99%,#000 101%) 50% 1.5rem/6rem 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* Styles for portrait tablets */
    .social-container {
      bottom: 0.5% !important;
      padding-right: 5% !important;
    }
    .textContentText {
      left: 12.5%;
      font-size: 15vh;
      animation: blackGlowText 2s infinite;
    }
    .headerMobile h1 {
      font-size: min(3rem, 2vw);
      padding-bottom: 5% !important;
      padding-left: 5% !important;
    }
    .headerMobile h6 {
      font-size: min(1.5rem, 1.5vw);
      left: 19%;
      padding-bottom: 2%;
    }
    .textContent {
      will-change: transform, opacity, background-color, z-index;
      margin-right: 0rem;
      margin-top: 10rem;
      padding: 8rem 1.75rem 5rem 1.75rem; /* Top Right Bottom Left */
      -webkit-mask: none;
      --mask:
      radial-gradient(4.77rem at 50% 6.60rem,#000 99%,#0000 101%) calc(50% - 8rem) 0/16rem 100%,
      radial-gradient(4.77rem at 50% -2.6rem,#0000 99%,#000 101%) 50% 4rem/16rem 100% repeat-x;
      -webkit-mask: var(--mask);
      mask: var(--mask);
    }
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles for landscape tablets */
    .galleryToggleSun {
      margin-top: 2%;
      min-height: 6vw;
      min-width: 6vw;
      top: auto; 
      margin-left: 10% !important;
    }
  }
  .subContentContainer{
    opacity: 0;
    transform: translate3d(0,-250%,0);
  }
  
  .subContent {
    background-color: gray !important;
    border-radius: 1rem;
    padding: 1rem;
    text-align: right;
    margin-left: 4rem;
    padding-left: 3rem;
    pointer-events: none;
    z-index: 8 !important;
  }
  .subContent2 {
    background-color: black !important;
    text-align: left;
    border-radius: 1rem;
    padding: 1rem;
    margin-right: 4rem;
    padding-right: 3rem;
    pointer-events: none;
    z-index: 8 !important;
  }
  .read {
    padding: 0.35rem 0.5rem 0 0;  /* Top Right Bottom Left */
    font-size: 1rem;
    opacity: 0;
    font-weight: 300;
    text-align:right;
    pointer-events: none;
    text-shadow: 6px 9px 9px #000;
  }
  .read2 {
    padding: 0.35rem 0 0 0.5rem;
    font-size: 1rem;
    opacity: 0;
    font-weight: 300;
    text-align:left;
    pointer-events: none;
    text-shadow: -6px 9px 9px black;
  }
  @media only screen and (max-width: 768px) {
    .subContent {
      font-size: 1rem; 
      margin-left: 1rem; 
      padding: 0.5rem; 
      padding-left: 1rem; 
    }
  
    .subContent2 {
      font-size: 1rem; 
      margin-right: 1rem;
      padding: 0.5rem;
      padding-right: 1rem;
    }
    .read {
      padding: 0.45rem 0.25rem 0 0;  /* Top Right Bottom Left */
      font-size: 0.65rem;
      opacity: 0;
      font-weight: 300;
      text-align:right;
      pointer-events: none;
      text-shadow: 6px 9px 9px #000;
    }
    .read2 {
      padding: 0.45rem 0 0 0.25rem;
      font-size: 0.65rem;
      opacity: 0;
      font-weight: 300;
      text-align:left;
      pointer-events: none;
      text-shadow: -6px 9px 9px black;
    }
  }
  .hidden {
    /* margin-top: 5rem; styleish? */
    opacity: 0;
    pointer-events: none;
  }

  .readContainer {
    /* background-color: transparent !important; */
    pointer-events: none;
  }
  .textState {
    position: relative;
    text-align: center !important;
    top: 20%;
    color: white;
    font-family: var(--fontFamily);
  }
  .galleryToggle {
    position: fixed !important;
    width:50%;
    height:0%;
    bottom: 0%;
    left: 25%;
    border-radius: 50%;
    opacity: 0;
    pointer-events: all;
    will-change: bottom, opacity, transform, height, z-index;
  }
  @media only screen and (max-width: 768px) {
    .galleryToggle {
      width: 75% !important;
      left: 10% !important;
    }
  }
  /* Create the glow effect using box-shadow */
  .galleryContent {
    font-size: 0.25vh;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: absolute !important;
    /* align-self: center;
    align-content: center;
    align-items: center;
    text-align: center; */
    left: 20%;
    top: 35%;
    color: grey;
    opacity: 0;
  }

  .galleryToggleSun {
    position: fixed;
    min-height: 6vw;
    min-width: 6vw;
    z-index: 9;
    background-color: red !important;
    text-align: center;
    top: auto;
    left: 5%;
    border-radius: 50%;
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    .galleryToggleSun {
      top: auto; 
      bottom: -1%;
      left: 15%; 
    }
  }
  @media only screen and (min-width: 1989px) {
    .galleryToggleSun {
      margin-top: 2%;
      min-height: 6vw;
      min-width: 6vw;
      top: auto; 
      left: 5%; 
      margin-left: 2%;
    }
    .header h1 {
      font-size: min(2rem, 1.25vw);
      padding-top: 5%;
      padding-left: 2%;
    }
    .header h6 {
      padding-top: 4%;
      padding-right: 1%;
    }
    .galleryContentSun {
      margin-top: -2.5% !important;
    }
    .textContent {
      margin-right: 10rem !important;
      padding-top: 9rem;
    }
  }
  /* Create the glow effect using box-shadow */
  .galleryToggleSun::before {
    content: "";
    position: absolute;
    width: 99%;
    height: 99%;
    border-radius: 50%;
    left: 0%;
    background: rgba(255, 255, 0, 0); /* Yellow color with transparent alpha */
    animation: glow 2s infinite; /* Apply the glow animation */
  }
  .galleryContentSun {
    font-size: 0.25vh;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    position: absolute !important;
    left: 20%;
    top: 35%;
    opacity: 0;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 75%;
    margin-left: -32.5%;
  }
  .verticalText {
    writing-mode: vertical-rl; 
    white-space: nowrap;
    min-width: 33%;
    color: whitesmoke;
    text-align: center;
    align-self: center;
    align-items: center;
    font-family: var(--secondFontFamily);
    letter-spacing: 0.035vh;
    margin-right: 0.4vw;
  }
 
  .mathToggle {
    pointer-events: all;
    cursor: pointer;
  }
  .horizontalText {
    min-width: 50%;
    text-align: left;
    padding-left: 0.1vw;
  }
  .horizontalText h5 {
    font-family: var(--fontFamily);
    color:white;
    margin-top: -0.5vh;
    padding-bottom: 0.20vh;
    text-decoration:underline;
    margin-bottom: 0.1vh;
  }
  .horizontalText p {
    font-size: 0.1vw;
    font-family: var(--secondFontFamily);
    font-weight: 500;
  }
  .horizontalText i {
    font-size: 0.08vw;
    font-family: var(--secondFontFamily);
    font-weight: 350 !important;
  }
  /* don't ask on positioning. */
  .horizontalText a {
    color: white;
    z-index: 200;
    /* position: absolute; */
    /* left: 132.5%; 
    top: 5%; */
    font-size: 0.075vw;
    padding-left: 0.4vw;
    font-style: italic;
  }
  .bookItems {
    line-height: 1.2em !important;
    min-width: 45% !important;
  }
  /* Define the glow animation */
  @keyframes glow {
    0% {
      box-shadow: 0 0 10px 5px rgba(1, 250, 43, 0.5); /* Initial glow properties */
    }
    50% {
      box-shadow: 0 0 20px 10px rgba(135, 255, 79, 0.6); /* Midpoint glow properties */
    }
    100% {
      box-shadow: 0 0 10px 5px rgba(84, 187, 0, 0.4); /* Final glow properties */
    }
  }
  @keyframes blackGlow {
    0% {
      box-shadow: 0 0 20px 10px rgba(128, 128, 128, 0.4);
    }
    50% {
      box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.6);
    }
    100% {
      box-shadow: 0 0 20px 10px rgba(128, 128, 128, 0.2);
    }
}
@keyframes blackGlowStrong {
  0% {
    box-shadow: 0 0 25px 10px rgba(128, 128, 128, 0.6);
  }
  50% {
    box-shadow: 0 0 15px 5px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 25px 10px rgba(128, 128, 128, 0.4);
  }
}
  @keyframes blackGlowText {
    0% {
      text-shadow: 0 0 7.5px rgba(0, 0, 0, 0.5);
      transform: scale(1) translateY(0);
      opacity: 0.4;
    }
    50% {
      text-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
      transform: scale(1.15) translateY(-50%);
      opacity: 1;
    }
    100% {
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
      transform: scale(1.1) translateY(0);
      opacity: 0.6;
    }
  }
  ::-webkit-scrollbar {display:none;}
  
.scrollable-container{
  min-height: 100% !important;
}

  .pageEnd {
    position: absolute;
    opacity: 0;
    background-color: red;
    top: 98%;
    left: 50%;
    z-index: 11;
    min-width: 5vw;
    min-height: 1vh;
  }
  .clickMe {
    position: absolute;
    top: 20%;
    left: 10%;
    color: whitesmoke;
    font-family: var(--secondFontFamily);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    opacity: 0;
  }
  .hoverPointer {
    cursor: pointer;
  }

  .poemGalleryText {
    /* justify-content: center; */
    left: 50%;
    transform: translateX(-50%);
    top: 25%;
    position: absolute;
    align-items: center;
    text-align: center;
    display: flex;
    min-width: 5vw;
    color: white;
    opacity: 50%;
    background-color: transparent !important;
  }
  .selectedPoemShort {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    /* padding-top: 15vh; */
    padding: 0vh 8vw 0 8vw; /* Top Right Bottom Left */
    overflow: hidden !important;
    /* vertical-align: top !important; */
    /* display: inline-block !important; */
    font-family: Arial, Helvetica, sans-serif;
  }
  .selectedPoemLong {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    top: 0;
    /* padding-top: 15vh; */
    padding: 8vh 18vw 8vh 18vw; /* Top Right Bottom Left */
    overflow: hidden !important;
    vertical-align: top !important;
    display: inline-block !important;
    font-family: Arial, Helvetica, sans-serif
  }
  
  .poemTitleL {
    color: grey;
    text-transform: uppercase;
    position: fixed;
    writing-mode: vertical-rl;
    text-orientation: upright;
    height: 100%;
    transform: translate(0, -50%);
    left: 0;
    top: 50% !important;
    padding-left: 15%;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    font-family: var(--fontFamily);
  }
  .poemTitleR {
    color: grey;
    text-transform: uppercase;
    position: fixed;
    writing-mode: vertical-lr;
    text-orientation:upright;
    right: 0;
    top: 50% !important;
    height: 100%;
    transform: translate(0, -50%);
    padding-right: 15%;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    font-family: var(--fontFamily);
  }
  @media only screen and (max-width: 768px) {
    .poemTitleL {
      left: -12.5%;
    }
    .poemTitleR {
      right: -12.5%;
    }
    .selectedPoemShort {
      padding: none;
    }
  }
  .scrollable {
    overflow: auto !important;
  }
  .unscrollable {
    overflow: hidden !important;
  }
  .social-container {
    padding: 0 0.5rem 1rem 0; /* Top Right Bottom Left */
    position: fixed;
    z-index: 101;
    bottom: 0;
    right: 0;
  }
  a.social {
    margin: 0 0.75rem;
    transition: transform 250ms;
    display: inline-block;
  }
  @media only screen and (max-width: 768px) {
    .social-container {
      padding: 0.55rem;
    }
    a.social {
      margin: 0 0.25rem; 
      font-size: 25px; 
    }
  }
  a.social:hover {
    transform: translateY(-0.5rem);
  }
  a.linkedin {
    color: white;
  }
  a.youtube {
    color: #eb3223;
  }
  a.soundcloud {
    color: orange;
  }
  a.facebook {
    color: #4968ad;
  }
  a.twitter {
    color: #49a1eb;
  }
  a.instagram {
    color: white;
  }
  
  .ellipsesContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .typing-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100 !important;
  }
  
  .upArrow {
    opacity: 1;
    position: fixed;
    bottom: 5%;
    left:50%;
    font-size: 5rem;
    font-weight: 400;
    animation: blackGlowText 2s infinite; 
    will-change: transform, opacity;
    display: block;
    pointer-events: all;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    .upArrow {
      position: absolute !important;
      left:42.5% !important;
      bottom: 2.5%;
      font-size: 3rem;
      animation: blackGlowText 2s infinite; 
    }
  }
  .circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    background-color: transparent;
    margin: 0 0.6vh; /* Adjust margin as needed */
    animation: scaleAndRotate 2s infinite ease-in-out;
    will-change:auto;
  }
  
  .circle1 {
    animation-delay: 0s;
  }
  
  .circle2 {
    animation-delay: 0.33s;
  }
  
  .circle3 {
    animation-delay: 0.66s;
  }
  

@keyframes scale {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* so the radial-gradient syntax is 'wrong' but it does something cool  */
@keyframes scaleAndRotate {
  0%, 100% {
    transform: scale(1) rotate(0deg);
    background: var(--bg-color);
  }
  50% {
    transform: scale(2) rotate(360deg);
    background: radial-gradient(180deg, var(--bg-color) 0%, black 100%); 
  }
}

.minHeight {
  min-height: 35vh;
}
.imgStyle {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 1rem; /* Adjust the gap as needed */
  background-color: black !important;
  border-radius: 1rem;
  padding: 1rem;
  margin-right: 4rem;
  padding-right: 3rem;
  pointer-events: none;
  z-index: 8 !important;
  min-height: 35vh;
}

.imgContainer {
  width: 75% !important; 
  height: 75% !important; 
  position: relative;
  z-index: 1;
  border-radius: 50%;
  left: 15%;
  bottom: -10%;
  border: black;
  background-color: black;
  border: 8px solid black;
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .imgStyle {
    padding: 0.55rem;
    min-width: 90vw !important;
  }
  .imgContainer {
    margin-right: 10vw;
    width: 85% !important;
    height: 85% !important;
  }
}
/* .imgContainer {
  width: 75% !important; 
  height: 75% !important; 
  position: relative;
  z-index: 1;
  border-radius: 50%;
  left: 15%;
  bottom: -10%;
  border: black;
  background-color: black;
  border: 8px solid black;
  opacity: 0;
} */
/* .aboutPhoto {
  opacity: 0;
} */
.imgContainer img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover; /* This will maintain the aspect ratio and cover the entire container */
  opacity: 0;
}